export default {
  statIds: {
    usersCount: '60ab8f2c141167afb7fd243c',
    usersUniquePageViewCount: '6102833fe821372b5def2fa0',
    usersByMediumByPageViewCount: '60ab8fa1141167afb7fd4143',
    usersByGenderByPageViewCount: '60ab8fae141167afb7fd43ad',
    activePushCount: '60ab8f10141167afb7fd1cab',
    purchasesCount: '60ab8f46141167afb7fd2bf0',
    purchasesWithVoucherCount: '60ab8f82141167afb7fd3aa6',
    bannerCount: '61b746e93b303542e985aaec',
    bannerCountPerBanner: '61b746e93b303542e985aaf8',
    bannerCountPerBannerPerMask: '61b746e93b303542e985ab02',
    purchasesTotalPriceSum: '60d3217ad236b76e1c29db8c',
    purchasesTotalPriceSumPerMask: '60d3217ad236b76e1c29db97',
    purchasesCountPerMask: '60d3217ad236b76e1c29dba2',
    purchasesAvgTotalPricePerMask: '60d3217ad236b76e1c29dbad',
    discountsSum: '60e44c9fc6ac832fdf451604',
    discountsSumPerMask: '60e44c9fc6ac832fdf45160f',
    productsCountPerMaskPerSku: '60eedccaaa2dd4fccd605c97',
    productsValuesPerMaskPerSku: '60eedccaaa2dd4fccd605ca2',
    wishlistSum: '60e5ad0966bd536377b3ef66',
    wishlistSumPerMask: '60e5ad0966bd536377b3ef71',
    churnCount: '611e79754d8e60a75b2ff26b',
    monthDevicesDistinct: '611f86784d8e60a75b61cfd2',
    purchasesPerPaymentType: '61ee8f5cbde689ed7677a570',
    purchasesPerPaymentProvider: '61ee8f5cbde689ed7677a579',
    purchasesPerDeliveryType: '61ee8f5cbde689ed7677a583',
    purchasesPerMaskPaymentTypePaymentProvider: '622f08b3db50261c791113df',
    activePPGCount: '6321ed0a95b103fc22430f08',
    purchasesValuePerMaskPaymentTypePaymentProvider: '63b2fd00b6ba0b4f65d51c8e',
    bannersConversion: '641d7f2a1b3ad81d0be100ad',
    appStoryConversion: '643967fbb6037df7bba71b89',
    searchPhrasesCount: '6458faaf962f9a141547f954',
    searchPhrasesCountPerStore: '646b630c58bdd3d196b6175f',
    searchPhrasesWithNextStep: '64c15240c49ffff93c2f6174',
    appStoryClicksPerProduct: '64d27e18c137ce01fa07578d',
    inboxClicks: '65671bd9060a98c96b8ba8cd',
    weatherHints: '658043ed2aab388443203efb',
    landingPageConversion: '6618264b3f391b1f94591100',
    landingPagesClicks: '6618264b3f391b1f94591101',
    couponPoolsCount: '662b9b715f970c73fe86b6e5',
    activationCodesCount: '66449c9983fbb0987cb1fa50',
    consentScreenDisplayedCount: '664c967df7897c28a89b6673',
    consentSelectAllCount: '664c967df7897c28a89b6674',
    purchasesWithRecommendationOriginAll: '668fc069bbdbae2e4427baad',
    purchasesValueWithRecommendationOriginAll: '668fc069bbdbae2e4427baae',
    recommendationsPerOrigin: '668fc069bbdbae2e4427baaf',
    recommendationsPerOriginAndSKU: '668fc069bbdbae2e4427bab0',
    purchasesPerRecommendationOriginGrouped: '668fc069bbdbae2e4427bab1',
    purchasesValuePerRecommendationOriginGrouped: '668fc069bbdbae2e4427bab2',
    questionnairePageViews: '66a24c6a868e7087f79d330c',
    questionnaireAnswers: '66a24c6a868e7087f79d330d',
    uniqueUsersDaily: '66ab8299908a7b8f8d568550',
    reallyUniqueUsersDaily: '66d18c03f4649ee3ae5d7c24',
    uniqueUsersAtLeast3events: '66dadc992c75af09e554c4c8',
    uniqueUsersAtLeast3eventsByMedium: '66dadc992c75af09e554c4c9',
    churnAtLeast3events: '66dadc992c75af09e554c4ca',
    monthDevicesDistinct3Events: '66daf82a6a87d3994a70daef',
    cancelledOrders: '670fdc55a79553ac85a7930d',
    purchasesCountPerMaskLogged: '6729fe18865aff7621fb8da2',
    purchasesAvgTotalPricePerMaskLogged: '6729fe29865aff7621fb8da3',
    reallyUniqueUsersDailyRegistered: '674da2e11ab1a1ed81fb8da2',
    weatherHintsConversionPerMask: '6753027e91dbe9827afb8da2',
    usersWithPurchases: '67728207f76a1603d6fb8da2',
    abandonedCarts: '67728207f76a1603d6fb8da3',
    purchasesValuePerProductBundleType: '67865a957ca6744c55fb8da3',
    purchasesValuePerProductBundleId: '67865a957ca6744c55fb8da2',
    recommendationClicksPerProductBundleId: '67865a957ca6744c55fb8da4',
    recommendationsProductsPerOriginValue: '67a37b71feb30bd103fb8da2',
  },
};
